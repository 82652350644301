import React from 'react'
import { IconButton, Box, Menu, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ModalArchiveEvent from '../ModalArchiveEvent'
import ModalActivateEventPage from '../ModalActivateEventPage'
import { updateEvent } from '../../../../api/app/events'
import {
	MoreVertRounded,
	EditRounded,
	RsvpRounded,
	ArchiveRounded,
	RotateLeftRounded,
	UnarchiveRounded,
	PublicRounded,
	VpnLockRounded,
	FileDownloadRounded,
	ShareRounded,
} from '../../atoms/Icon'

export const Actions = ({ event }) => {
	const { id, uuid, publishedMedia, isPrivate, status, inGlacierStorage, isRestoring } = event

	const dispatch = useDispatch()

	const activateModal = useDisclosure()
	const archiveModal = useDisclosure()

	const handleUnArchivePublishedEvent = () => {
		var eventData = { id, status: 'published' }
		dispatch(updateEvent(eventData))
	}

	const handleUnArchiveEvent = () => {
		var eventData = { id, status: 'active', contributorsNotified: false }
		dispatch(updateEvent(eventData))
	}

	const handleTogglePrivate = () => {
		var eventData = { id, isPrivate: !isPrivate }
		dispatch(updateEvent(eventData))
	}

	return (
		<>
			{status != 'saveTheDate' && (
				<>
					<Box
						sx={{
							zIndex: '4',
							pos: 'absolute',
							top: '1rem',
							right: '1rem',
						}}>
						<Menu>
							<MenuButton
								variant="iconMenu"
								as={IconButton}
								aria-label="Options"
								icon={<MoreVertRounded color="link" />}
								onClick={(e) => e.stopPropagation()}
								w="48px"
							/>
							<MenuList onClick={(e) => e.stopPropagation()}>
								{/** Published - Actions */}
								{status == 'published' && (
									<>
										{isPrivate ? (
											<MenuItem icon={<PublicRounded />} onClick={handleTogglePrivate}>
												Make Public
											</MenuItem>
										) : (
											<MenuItem icon={<VpnLockRounded />} onClick={handleTogglePrivate}>
												Make Private
											</MenuItem>
										)}
										<MenuItem icon={<RotateLeftRounded />} onClick={activateModal.onOpen}>
											Reopen Event
										</MenuItem>
										<MenuItem icon={<ArchiveRounded />} onClick={archiveModal.onOpen}>
											Archive
										</MenuItem>
										<MenuDivider />
										{publishedMedia && publishedMedia.signedLocationUrl && (
											<MenuItem
												as="a"
												icon={<FileDownloadRounded />}
												href={`${publishedMedia.signedLocationUrl}`}
												download={`${publishedMedia.signedLocationUrl}`}
												target="_blank"
												className="download"
												rel="noreferrer">
												Download
											</MenuItem>
										)}
										<MenuItem as={Link} to={`/event/${uuid}/invitation`} icon={<ShareRounded />}>
											Share
										</MenuItem>
									</>
								)}

								{/** Publishing - Actions */}
								{status == 'publishing' && (
									<>
										<MenuItem icon={<RotateLeftRounded />} onClick={activateModal.onOpen}>
											Reopen Event
										</MenuItem>
										<MenuItem icon={<ArchiveRounded />} onClick={archiveModal.onOpen}>
											Archive
										</MenuItem>
									</>
								)}

								{/** Active - Actions */}
								{status == 'active' && (
									<>
										<MenuItem as={Link} to={`/event/${uuid}/edit`} icon={<EditRounded />}>
											Edit
										</MenuItem>
										<MenuItem as={Link} to={`/event/${uuid}/invitation`} icon={<RsvpRounded />}>
											Invite People
										</MenuItem>
										<MenuDivider />
										<MenuItem icon={<ArchiveRounded />} onClick={archiveModal.onOpen}>
											Archive
										</MenuItem>
									</>
								)}

								{/** Archived - or - Abandoned Actions */}
								{(status == 'archived' || status == 'abandoned') && (
									<>
										{publishedMedia ? (
											<MenuItem
												icon={<UnarchiveRounded />}
												onClick={handleUnArchivePublishedEvent}>
												Un-Archive
											</MenuItem>
										) : (
											<MenuItem icon={<RotateLeftRounded />} onClick={handleUnArchiveEvent}>
												Continue Event
											</MenuItem>
										)}
									</>
								)}
							</MenuList>
						</Menu>
					</Box>

					<ModalActivateEventPage
						isOpen={activateModal.isOpen}
						onClose={activateModal.onClose}
						event={event}
					/>
					<ModalArchiveEvent isOpen={archiveModal.isOpen} onClose={archiveModal.onClose} event={event} />
				</>
			)}
		</>
	)
}
